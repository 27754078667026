.bar
  display: flex
  justify-content: space-between
  align-items: center
  background-color: #1C3137
  color: $white
  position: relative

.logo-block
  display: flex
  align-items: center
  padding: 5px 10px 5px 3rem
  @include v-sm
    padding: 10px

.logo-dashboard
  width: 129px
  height: 65.987px

.right-buttons-block
  display: flex
  align-items: center
  padding: 5px 10px 5px 10px
  margin-right: 35px
  @include v-sm
    margin-right: 5px

.right-buttons-block > *:not(:last-child)
  margin-right: 18px

.right-button
  display: flex
  width: 33px
  height: 33px
  justify-content: center
  align-items: center
  text-decoration: none
  &:hover
    color: $marine

.right-profile-image
  width: 40px
  height: 40px
  object-fit: cover
  border-radius: 100%

.left-bar
  background-color: #294B55
  text-align: center
  width: 90px
  min-height: 100vh
  overflow: auto

.left-bar-icons-list
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 0
  width: 100%

.left-bar-icons
  margin-bottom: 0
  padding: 1rem 0
  width: 100%

.left-bar-icons:hover
  background-color: #1C3137

.left-bar-icon
  width: 24px
  height: 22px

.left-profile-image
  padding: 2rem 0 2.5rem 0

.left-profile-image-icon
  width: 54px
  height: 54px
  object-fit: cover
  border-radius: 100px

.content-page
  flex-grow: 1

.breadcrumbs
  color: #294B55
  font-family: $noto
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: normal
  @include v-sm
    margin-left: 0

.dashboard-header
  padding: 25px
  text-align: left
  display: flex
  justify-content: space-between
  align-items: flex-end
  @include v-sm
    padding: 20px

.search-container
  position: relative

.profile-heading
  text-align: left
  color: #1C3137
  font-family: $inter
  font-size: 48px
  font-style: normal
  font-weight: 700
  line-height: normal
  margin-bottom: 0
  @include v-sm
    font-size: 30px

.form-profile-field
  border-radius: 5px !important
  margin: 5px 0 5px 0

.profile-photo
  width: 170px
  height: 170px
  border-radius: 100%
  object-fit: cover
  @include v-sm
    width: 120px
    height: 120px

.search-icon
  border: 1px solid #1C3137
  border-radius: 0 5px 5px 0
  background-color: white
  padding: 5px
  border-left: 0

.btn-search
  border: 1px solid #1C3137
  border-radius: 5px 0 0 5px
  padding: 5px
  border-right: 0

.btn-search::focus
  outline: none
  border: 1px solid coral
  box-shadow: 0 0 5px coral

.logo-right-block
  float: right
  @include v-sm
    float: none !important
    margin-top: 1rem
