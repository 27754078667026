.registration-header
  color: #000
  font-size: 40px
  font-family: $inter
  font-style: normal
  font-weight: 800
  line-height: normal
  text-transform: uppercase
  margin-bottom: 24px

.logo
  @include v-sm
    height: 70px

.logo-container
  @include v-sm
    position: static
    top: auto
    left: auto
    text-align: center
