.s
  &-10
    height: 10px
  &-20
    height: 20px
  &-30
    height: 30px
  &-40
    height: 40px
  &-50
    height: 50px
  &-60
    height: 60px
  &-70
    height: 70px
  &-80
    height: 80px
  &-90
    height: 90px
  &-100
    height: 100px

.section-space
  margin-top: 176px
  @include v-lg
    margin-top: 90px
  @include v-sm
    margin-top: 60px

.footer-space
  padding-bottom: 10rem
  @include v-md
    padding-bottom: 5rem

.c
  &-marine
    color: $marine !important
  &-violet
    color: $violet
  &-blue-shade-1
    color: $blue-shade-1
  &-blue-shade-2
    color: $blue-shade-2
  &-blue-shade-3
    color: $blue-shade-3

.cursor-pointer
  cursor: pointer
