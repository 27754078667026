.button
  border: 0
  padding: 5px 20px
  border-radius: 49.772px
  font-size: 16px
  display: flex
  align-items: center
  justify-content: center
  font-weight: bold
  text-decoration: none
  &:hover
    background-color: $marine

.btn-radius
  border-radius: 22px !important

.sign-up-button
  display: flex
  align-items: center
  font-size: 20px
  margin-left: 6px
  padding: 10px 15px
  text-decoration: none
  border-radius: 5px
  background: $primary-color
  color: #FFF !important
  font-family: $inter
  font-style: normal
  font-weight: 500
  line-height: normal
  &:hover
    background-color: $marine
  @include v-lg
    font-size: 16px
    padding: 8px 12px
    height: 36px
  @include v-sm
    padding: 8px 8px
    font-size: 14px

.employees-button
  background: $primary-color
  color: $white
  white-space: nowrap
  font-size: 24px

.seekers-button
  color: $white
  background: $black
  white-space: nowrap
  font-size: 24px

.employees-button-about
  height: 93px
  border-radius: 82.891px
  background: #0084E4
  color: white
  font-family: $inter
  font-size: 30px
  font-style: normal
  font-weight: 700
  line-height: 41px

.seekers-button-about
  height: 93px
  border-radius: 82.891px
  background: #000
  color: white
  font-family: $inter
  font-size: 30px
  font-style: normal
  font-weight: 700
  line-height: 41px

.employees-button-about,
.seekers-button-about
  @include v-xl
    height: 60px
    width: 250px
    font-size: 16px
  @include v-sm
    font-size: 12px
    width: 170px
    height: 40px

.create-cv-button
  height: 93px
  margin-top: 20px
  border-radius: 82.891px
  background: #0084E4
  color: white
  font-family: $inter
  font-size: 28px
  font-style: normal
  font-weight: 700
  line-height: 41px
  box-shadow: 0 0 10px 10px rgba(83, 132, 197, 0.5)
  @include v-lg
    font-size: 15px
    height: 60px
  @include v-sm
    font-size: 12px
    height: 40px

.employees-button,
.seekers-button
  @include v-lg
    font-size: 18px
  @include v-sm
    font-size: 15px

.button-create-account
  display: flex
  align-items: center
  justify-content: center
  font-size: 16px
  font-family: $inter
  font-style: normal
  line-height: normal
  width: 200px
  height: 40px
  background-color: black
  color: white
  margin-bottom: 20px
  margin-top: 1rem
  border-radius: 30px
  padding-left: 15px
  border: 0
  &:hover
    background-color: $marine
  @include v-sm
    font-size: 14px !important

.button-icon
  margin-right: 4px
  @include v-lg
    width: 28px
  @include v-sm
    display: none

.btn-mobile
  @include v-sm
    font-size: 1rem

.button-container-about-us
  position: absolute
  top: 0
@media (max-width: 420px)
  .button-container-about-us
    position: relative
    margin-bottom: auto
