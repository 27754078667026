.title-1
  color: $black
  font-family: $inter
  font-size: 100px
  font-style: normal
  font-weight: 900
  line-height: 104.996%
  text-align: right
  @include v-xxl
    text-align: center
    font-size: 64px
  @include v-lg
    font-size: 48px
  @include v-sm
    font-size: 42px

.sub-title-1
  text-align: right
  color: $primary-color
  font-family: $noto
  font-size: 30.5px
  font-style: normal
  font-weight: 500
  line-height: normal
  @include v-xl
    text-align: center
  @include v-lg
    font-size: 24px

.title-2
  margin-bottom: 96px
  color: $black
  font-size: 67px
  font-style: normal
  font-weight: 900
  text-transform: uppercase
  text-align: center
  @include v-xxl
    font-size: 52px
  @include v-lg
    font-size: 38px
    margin-bottom: 60px
  @include v-sm
    font-size: 30px

.title-3
  color: $black
  font-size: 42px
  font-style: normal
  font-weight: 600
  text-align: center
  @include v-xxl
    font-size: 32px
  @include v-lg
    font-size: 28px

.title-4
  font-size: 36px
  font-style: normal
  font-weight: 900
  line-height: 38px
  text-transform: uppercase
  margin-top: 1rem
  @include v-sm
    font-size: 25px
    line-height: 28px

.title-5
  color: $black
  font-size: 48px
  font-style: normal
  font-weight: 500
  line-height: normal
  font-family: $noto
  @include v-lg
    font-size: 34px
  @include v-md
    font-size: 28px

.title-6
  color: $black
  font-size: 26px
  font-style: normal
  font-weight: 600
  line-height: normal
  font-family: $noto
  @include v-lg
    font-size: 22px
  @include v-md
    font-size: 20px

.text-1
  font-size: 30px
  font-family: $noto
  font-weight: 500
  line-height: normal
  @include v-xl
    font-size: 20px
  @include v-md
    font-size: 18px

.text-1-inter
  font-size: 30px
  font-family: $inter
  font-weight: 500

.text-2
  font-size: 24px
  font-family: $noto
  font-weight: 500
  @include v-sm
    font-size: 16px

.text-3
  font-family: $noto
  font-size: 24px
  font-style: normal
  font-weight: 400
  line-height: normal
  @include v-lg
    font-size: 20px
  @include v-md
    font-size: 18px

.capital-letter
  font-family: $noto
  font-size: 46px
  font-style: normal
  font-weight: 900
  line-height: normal
  letter-spacing: 2px
  @include v-md
    font-size: 38px

.underline-title
  text-decoration: underline
  text-decoration-thickness: 5px
  text-decoration-color: $primary-color
  text-underline-offset: 14px
  @include v-xxl
    text-underline-offset: 12px
  @include v-lg
    text-underline-offset: 8px
    text-decoration-thickness: 3px
  @include v-sm
    text-underline-offset: 6px
    text-decoration-thickness: 3px

.questions-header
  font-size: 48px
  font-style: normal
  font-weight: 500
  line-height: normal
  font-family: $noto
  @include v-lg
    font-size: 34px
  @include v-md
    font-size: 28px

.question-list
  padding-left: 3.5rem
  display: flex
  flex-direction: column
  gap: 1.5rem
  @extend .title-5
  @include v-lg
    padding-left: 2.5rem
  @include v-md
    padding-left: 2rem

.form-header
  color: #000
  font-size: 40px
  font-family: $inter
  font-style: normal
  text-align: center
  font-weight: 800
  line-height: normal
  text-transform: uppercase
  margin-top: 32px
  @include v-md
    font-size: 28px

.form-text
  font-family: $inter
  font-size: 24px
  font-style: normal
  font-weight: 500
  line-height: normal
  @include v-sm
    font-size: 18px

.form-text-2
  margin-top: -8px
  margin-left: 1rem
  font-family: $inter
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
  @include v-sm
    font-size: 12px

.nav-text
  font-size: 24px
  font-weight: 500
  letter-spacing: 1px
