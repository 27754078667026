.input-field
  width: 100%
  padding-left: 1rem
  margin-bottom: 10px
  border: 3px solid #306778 !important
  border-radius: 56px
  text-align: center
  font-size: 20px
  text-align: left
  background: #FFF
  @include v-sm
    font-size: 16px
    
.checkbox-field
  color: #306778
  font-size: 13.041px
  font-family: $inter
  font-style: normal
  font-weight: 400
  line-height: normal
  text-align: left
  align-items: center
  flex-direction: row

.input-textarea
  border-radius: 20px !important

.photo-text-paragraph
  word-break: break-all
