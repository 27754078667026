.begining-header
  color: #000
  font-family: $inter
  font-size: 48px
  font-style: normal
  font-weight: 700
  line-height: normal
  @include v-sm
    font-size: 25px

.chose-block
  display: flex
  justify-content: space-between

.questions-form
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.upload-container
  display: flex
  justify-content: center

// Buttons
.button-block
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-end

.btn-next, .btn-back
  width: 200px
  border-radius: 2rem
  margin-bottom: 1rem
  font-size: 25px
  font-family: $inter
  font-style: normal
  font-weight: 700
  @include v-sm
    font-size: 20px
    padding: 0 1rem 0 1rem

.btn-next
  margin-left: 8px

.btn-next-begin
  @extend .btn-back

.button-upload
  overflow: hidden

.upload-button
  text-align: center
  font-family: $inter
  font-size: 20px
  font-style: normal
  font-weight: 700
  line-height: 104.996%
  border-radius: 50px

//Accordion 

.accordion-header
  color: #000
  font-family: $inter
  font-size: 24px
  font-style: normal
  font-weight: 700
  line-height: normal

.accordion-item
  background-color: transparent
  border: 0

.accordion-body
  padding: 0

.accordion-button
  border: 2px solid #306778
  background-color: rgba(195, 241, 255, 0.19)

.accordion-button.collapsed
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)

#additional-accordion .accordion-button::after
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E")

.photo-icon
  width: 100%
  height: 100%
  object-fit: cover
