.navbar
  width: 100%
  font-size: 20px
  @include v-lg
    font-size: 16px
  @include v-sm
    font-size: 14px

.navbar-wrapper
  @include v-sm
    display: none

.navbar-logo img
  @include v-lg
    width: 140px

.nav-item
  position: relative
  display: flex
  height: 29px
  margin: 8px
  justify-content: center
  align-items: center
  flex-shrink: 0
  text-decoration: none
  border-radius: 8px
  &:hover
    &::after
      content: ""
      position: absolute
      right: -14px
      height: 5px
      width: 5px
      border-radius: 50%
      background-color: black
      display: block
  @include v-lg
    margin: 6px
    &:hover
      &::after
        width: 4px
        height: 4px
        right: -8px

.navbar-lang
  span
    cursor: pointer

.navbar-burger
  position: relative
  display: none
  align-items: center
  width: 28px
  height: 24px
  cursor: pointer
  .tt, .mm, .bb
    position: absolute
    width: 100%
    border-bottom: 3px solid black
    border-radius: 2px
  .tt
    top: 0
  .mm
    top: 50%
    transform: translateY(-50%)
  .bb
    bottom: 0
  @include v-sm
    display: flex

.modal-menu
  display: flex
  transform: translateX(-100%)
  justify-content: center
  align-items: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: $blue-light
  transition: transform .1s
  z-index: 100
  .modal-menu-close-icon
    display: flex
    align-items: center
    position: absolute
    width: 32px
    height: 32px
    right: 16px
    top: 26px
    cursor: pointer
    .tt, .bb
      position: absolute
      width: 100%
      border-bottom: 3px solid black
      border-radius: 2px
    .tt
      transform: rotate(45deg)
    .bb
      transform: rotate(-45deg)

.modal-menu-visible
  @include v-sm
    transform: translateX(0%)
    transition: transform .4s
