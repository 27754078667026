html, body
  height: 100%
  width: 100%
  margin: 0
  padding: 0

body
  box-sizing: border-box
  font-family: $inter

::-webkit-scrollbar
  width: 0

.container
  display: block
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 100vh
  position: relative
  @include v-sm
    padding: 0 16px

.padding-container
  padding: 2rem 5rem 2rem 5rem

a, * a
  color: inherit !important
  text-decoration: none !important

nav
  width: 100%
  height: auto
  top: 0
  left: 0

.background
  background: url("../../assets/images/background.svg")
  filter: blur(60px)
  z-index: -1
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-repeat: no-repeat
  background-position: center

.advantages-image
  max-width: 150px
  max-height: 150px
  width: auto
  height: auto
  @include v-sm
    max-width: 100px
    max-height: 100px

.cv-overlay
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: absolute
  top: 0
  width: 100%
  height: 100%
  color: $white
