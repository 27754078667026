.form-container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  min-height: calc(100vh - 107px)
  padding: 3rem 0
  @include v-sm
    min-height: calc(100vh - 70px)

.form-control
  border-radius: 56px !important
  border: 3px solid #306778 !important

.form-select
  border-radius: 56px !important

.form-label
  width: 100%
  float: left
  color: #000
  font-family: $inter
  font-size: 24px
  font-style: normal
  font-weight: 500
  line-height: normal
  margin-left: 1rem
  @include v-sm
    font-size: 18px