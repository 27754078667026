footer
  background-color: #000

.footer-icon
  width: 50px
  height: 50px

.footer-container
  min-height: 10px !important

.footer-column
  flex: 1 1 auto
  padding: 0 1rem

h5
  color: white
  margin-bottom: 1.5rem !important

ul
  list-style: none
  padding: 0 !important

  li
    margin-bottom: 1rem

  a
    color: white
    text-decoration: none
