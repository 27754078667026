@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@300;400;500;600;800;900&family=Noto+Serif:wght@400;500;600;700;900&display=swap')

$body: #fff
$dark: #3D4B6D
$white: #fff
$black: #000

$button: #4C6FBF
$intro: #2A2F39

$blue: #40BAFF
$blue-light: #C3F1FF
$blue-shade-1: #4AB7D9
$blue-shade-2: #0084E4
$blue-shade-3: #306778
$violet: #8E4A95
$pink: #EA4E98
$marine: #05d5ff
$footer-bg-color: #000

$primary-color: #0d6efd
$secondary-color: #000

$inter: 'Inter Tight', sans-serif
$noto: 'Noto Serif', serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
